import { useState } from "react"
import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { Label, PanelType, Spinner, SpinnerSize, Stack } from "@fluentui/react"
import { ControlledTextField } from "../lib/forms/ControlledTextField"
import { useForm } from "react-hook-form"
import { ISdkCustomerCustomPropertyProfileCreateRequest, ISdkCustomerCustomPropertyProfileItem } from "../lib/sdk/models/ISdkCustomerCustomPropertyProfile"
import { SdkCustomerCustomPropertyProfileClient } from "../lib/sdk/SdkCustomerCustomPropertyProfileClient"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook"
import { UnControlledListFormsContainer } from "../lib/forms/UnControlledListFormsContainer"
import { ControlledDropdown } from "../lib/forms/ControlledDropdown"
import uuid from "react-uuid"
import { FluentProvider, makeStyles } from "@fluentui/react-components"
import { useAppShellContext } from "../lib/ds/components/AppShell"
import { ControlledNumberField } from "../lib/forms/ControlledNumberField"

const makeCustomPropertyDataEditorStyle = makeStyles({
    root: {        
        display: 'flex',
        flexDirection: 'row',                
        width: '100%',        
        columnGap: '10px'
    },
    key: {
        width: '100%'
    },
    type: {
        minWidth: '180px'
    }            
});


type AddCustomPropertyProfileBaseData = {
    name: string
    description: string
    orderId: number       
};

export interface IAddOrEditCustomPropertyProfileDialogProps extends IAppWindowBaseProps {   
    tenantId: string
    parent: string
    parentType: string
    itemId?: string    
}

export const AddOrEditCustomPropertyProfileDialog = (props: IAddOrEditCustomPropertyProfileDialogProps) => {
    
    const [isProcessing, setIsProcssing] = useState<boolean>(false)    
    const [isPreparing, setIsPreparing] = useState<boolean>(false)        

    const [properties, setProperties] = useState<ISdkCustomerCustomPropertyProfileItem[]>([])
    const [propertiesChanged, setPropertiesChanged] = useState<boolean>(false)
        
    const propertyTypes = [{key: 'String', text: 'String'}, {key: 'Number', text: 'Number'}, {key: 'Boolean', text: 'Boolean'}, {key: 'Secret', text: 'Secret/Password'}]
    
    const appShellContext = useAppShellContext()
    const auth = useAuthInContext(props.tenantId as string);           
    
    const dissmissDialog = () => {
        setIsProcssing(false)     
        setProperties([])
        setPropertiesChanged(false)  
        reset() 
        if (props.dismissDialog)
            props.dismissDialog()
    }

    // load the item to edit
    useEffectAsync(async () => {

        if (!props.itemId) { 
            reset({
                name: '',
                description: '',
                orderId: 10                
            })

            return
        }

        setIsPreparing(true)

        const sdkClient = new SdkCustomerCustomPropertyProfileClient(props.tenantId, auth.currentToken as string)
        const item = await sdkClient.getCustomPropertyProfile(props.itemId)

        reset({
            name: item.name,
            description: item.description,
            orderId: item.orderId            
        })

        setProperties(item.properties)
                
        setIsPreparing(false)

    }, [props.itemId])
        
    const { handleSubmit, control, reset, formState } = useForm<AddCustomPropertyProfileBaseData>({
        defaultValues: {
            name: '',
            description: '',
            orderId: 10          
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });

    const onSubmit = (): Promise<string | void> => {

        // handle the submission of the base form 
        const submitHandler = handleSubmit((data: AddCustomPropertyProfileBaseData) => {
                        
            let parentType = 'CustomerGroup'
            if (props.parentType === 'customer') { parentType = 'Customer' }
            else if (props.parentType === 'reports') { parentType = 'Reports' }

            const createRequest: ISdkCustomerCustomPropertyProfileCreateRequest = {
                targetId: props.parent,
                targetType: parentType,
                name: data.name,
                description: data.description,
                orderId: data.orderId,
                properties: properties               
            }

            // at this point we can create a new item and close the dialog
            const sdkClient = new SdkCustomerCustomPropertyProfileClient(props.tenantId, auth.currentToken as string)

            if (props.itemId) {
                return sdkClient.updatecustomPropertyProfile(props.itemId, createRequest)            
            } else {
                return sdkClient.createCustomPropertyProfile(createRequest)            
            }
        })
        
        return submitHandler()
        .then(() => { dissmissDialog() })
        .finally(() => { setIsProcssing(false)})   
    }

    const onAddProperty = (property: ISdkCustomerCustomPropertyProfileItem) => {
        property.id = uuid()
        setProperties(prevProperties => [...prevProperties, property])
        setPropertiesChanged(true)
        return Promise.resolve()
    }

    const onDeleteProperty = (property: ISdkCustomerCustomPropertyProfileItem) => {
        setProperties(prevProperties => prevProperties.filter(p => p.id !== property.id))
        setPropertiesChanged(true)
        return Promise.resolve()
    }

    const onUpdateProperty = (item: ISdkCustomerCustomPropertyProfileItem) => {
        setProperties(prevProperties => [...prevProperties.map(p => p.id === item.id ? item : p)])
        setPropertiesChanged(true)
        return Promise.resolve()
    }

    const editorStyles = makeCustomPropertyDataEditorStyle()

    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={props.itemId ? 'Update custom property profile' : 'Add new custom property profile'}
            onDismiss={dissmissDialog}                                    
            type={PanelType.large}
            progessMessage={(props.itemId ? 'Updating existing' : 'Creating new') + ' custom property profile'}
            submitLabel={props.itemId ? 'Update' : 'Create'}
            dissmissLabel={'Cancel'}                        
            isValid={formState.isValid && (formState.isDirty || propertiesChanged)}
            onSubmit={onSubmit}
            errorMessagePrefix= {props.itemId? 'Error updating the custom property profile!' : 'Error creating the custom property profile!'}>
                
                <FluentProvider theme={appShellContext.themeV9}>

                    { isPreparing ? (
                        <Spinner size={SpinnerSize.large} label="Loading data..." labelPosition="bottom" style={{height: '100%'}}/>
                    ) : (
                        <div>
                            <div style={{marginBottom: '10px'}}>
                                <p>Custom Property Profiles allow to define a set of properties which can be 
                                    maintained at the customer. All the properties are usabled in other profiles, 
                                    reports or via API. Please configure custom property profile below:</p> 
                            </div>

                            <div>
                                <div>
                                    <Stack tokens={{ childrenGap: 15 }}>                
                                        <ControlledTextField label={'Profile Name'} control={control} name={'name'} rules={{ required: 'a valid valud is required'}} disabled={isProcessing} />
                                        <ControlledTextField label={'Description'} control={control} name={'description'} disabled={isProcessing} multiline resizable />
                                        <ControlledNumberField label={'Category Order at Customer Dialog'} control={control} name={'orderId'} disabled={isProcessing} />                                                                                                                
                                    </Stack>

                                    <p>The list below allows to define all required properties in the custom property profile. Ensure that the correct data type is selected"</p>                      

                                    <Stack tokens={{ childrenGap: 15 }}>   
                                        <Label>Custom Properties</Label>                             
                                        <UnControlledListFormsContainer disabled={false} items={properties} itemDefaultValues={{name: '', dataType: 'String', id: ''}} onAddItem={onAddProperty} onDeleteItem={onDeleteProperty} onUpdateItem={onUpdateProperty} renderEditor={(controlEditor) => (
                                            <div className={editorStyles.root}>                            
                                                <ControlledTextField className={editorStyles.key} key={'name'} control={controlEditor} name={'name'} placeholder={'Name of the property...'} disabled={false} />                                                    
                                                <ControlledDropdown className={editorStyles.type} key={'datatype'} control={controlEditor} name={'dataType'} disabled={false} rules={{ required: 'a valid value is required'}} options={propertyTypes} />                        
                                            </div>            
                                        )} />            
                                    </Stack>
                                </div>
                            </div>                                                
                        </div>
                    )}
                
                </FluentProvider>

        </PanelWithDefaultFooter>
    )
}
