import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow";
import { ISdkSearchResultItem } from "../lib/sdk/models/ISdkSearchResultItem";
import { SearchGeneralPanel } from "./SearchGeneralPanel";

export interface IManagedLinkedCustomerRegisterDialogProps extends IAppWindowBaseProps {   
    tenantId: string    
    dataIntegrationId: string
    onCreateCustomer: (customerId: string, customerName: string) => Promise<void>
}

export const ManagedLinkedCustomerRegisterDialog = (props: IManagedLinkedCustomerRegisterDialogProps) => { 

    const onCreateCustomer = (item: ISdkSearchResultItem) => {
        return props.onCreateCustomer(item.id, item.text)
    }
    
    return (
        <SearchGeneralPanel 
            isVisible={props.isVisible}
            dismissDialog={props.dismissDialog}
            tenantId={props.tenantId} 
            rootId={props.dataIntegrationId} 
            searchTypes={['dataintegration/datasegments']} 
            title={'Register new customer from data segment'} 
            description={'Dependent on the connected system customer related data is organized in data segements. Search for a sepcific data segement to create a new customer from it! It could take up to 24hrs until the data segments being populated here!'} 
            searchPlaceholder={'Search for data segments'} 
            searchResultHeader={'Matching Data-Segements'}
            actions={[
                { text: 'Create customer', onClick: onCreateCustomer }
            ]}
        />
    )
}
