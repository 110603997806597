import { ISdkDataIntegration } from "./models/ISdkDataIntegration";
import { ISdkDataIntegrationCreate } from "./models/ISdkDataIntegrationCreate";
import { ISdkDataintegrationStats } from "./models/ISdkDataintegrationStats";
import { ISdkObjectTemplate } from "./models/ISdkObjectTemplate";
import { SdkClient } from "./SdkClient";
import { SdkClientServiceDiscoveryService } from "./SdkClientServiceDiscoveryService";

export class SdkCustomerDataIntegrationClient {

    private sdkClient: SdkClient<any>        
    private sdkTemplateClient: SdkClient<ISdkObjectTemplate>

    constructor(tenantId: string, token: string)         
    {            
        this.sdkClient = SdkClientServiceDiscoveryService.Discover<any>('/api/v1/tenants/' + tenantId + '/svp/integrations', token);        
        this.sdkTemplateClient = SdkClientServiceDiscoveryService.Discover<ISdkObjectTemplate>('/api/v1/tenants/' + tenantId + '/svp/integrations/templates', token);
    }
    
    listDataIntegration(): Promise<ISdkDataIntegration[]> 
    {                
        return this.sdkClient.list('/')
    }

    listDataIntegrationTemplates(): Promise<ISdkObjectTemplate[]> 
    {        
        return this.sdkTemplateClient.list('');            
    }

    getDataIntegration(id: string): Promise<ISdkDataIntegration> 
    {                
        return this.sdkClient.get('/' + id)
    }

    getDataIntegrationStats(id: string): Promise<ISdkDataintegrationStats> {
        return this.sdkClient.get('/' + id + '/stats')
    }
    
    createDataIntegration(integration: ISdkDataIntegrationCreate): Promise<ISdkDataIntegration> 
    {            
        const payload = {
            name: integration.name,
            type: integration.type,
            comment:integration.comment,
            fieldValues: integration.fieldValues,
            manageCustomers: integration.manageCustomers,
            parentGroupPath: integration.parentGroupPath
        }

        return this.sdkClient.post('/', payload).then(async (location) => {
            return this.sdkClient.getFromAbsoluteUri(location as string);
        })        
    }

    updateDataIntegration(id: string, integration: ISdkDataIntegrationCreate): Promise<ISdkDataIntegration> 
    {
        const payload = {
            name: integration.name,
            type: integration.type,
            comment: integration.comment,
            fieldValues: integration.fieldValues,
            manageCustomers: integration.manageCustomers,
            parentGroupPath: integration.parentGroupPath
        }

        return this.sdkClient.put('/' + id, payload).then(async () => {
            return this.getDataIntegration(id)
        })              
    }

    deleteDataIntegration(id: string): Promise<Boolean> 
    {
        return this.sdkClient.delete('/' + id);
    }

    reInitializeDataIntegration(id: string): Promise<Boolean> 
    {
        return this.sdkClient.postToGetStatus('/' + id + '/reinitialize', undefined);
    }

    syncDataIntegration(id: string, period: string, cleanUpData: boolean, forcePrevious: boolean): Promise<Boolean> 
    {
        return this.sdkClient.postToGetStatus('/' + id + '/periods/' + period + '/sync', { cleanUpData: cleanUpData, forcePrevious: forcePrevious });
    }    

    registerLinkedDatasource(id: string, externalCustomerId: string, customerName: string): Promise<Boolean> 
    {
        return this.sdkClient.postToGetStatus('/' + id + '/linkeddatasources', { externalCustomerId: externalCustomerId, name: customerName});
    }
}