import { ISdkDashboardWidgetTemplate } from "./models/ISdkDashboardWidgetTemplate"

export class SdkCustomerDashboardWidgetTemplateClient {

    _templates: ISdkDashboardWidgetTemplate[] = [
        {             
            title: 'Image', 
            description: 'A widget to display an image in the dashboars. Images could come from a local file or from a remote location.',
            hasDatasource: false,
            type: 'ImageWidget',                
            attributes: [
                { id: 'ImageUri', name: 'Image-Uri', type: 'string', description: 'The URI of the image to display' }
            ]
        },
        {             
            title: 'Simple Value', 
            description: 'Allows to render a simple value as a result of an aggregation operation.',
            hasDatasource: true,
            type: 'SimpleValueWidget',                
            attributes: [
                { id: 'periodsEnabled', name: 'Enable support for periods', type: 'bool', description: 'If true, the widget will support switching between different time periods' },
                { id: 'periodsAll', name: 'Show manually triggered periods', type: 'bool', description: 'If true, the widget will support switching between different time periods include manually triggered' },
                { id: 'valueNoDataAsZero', name: 'Render \'No Data\' as 0', type: 'bool', description: 'Should the collection does not contain any data, the tile shows a 0 instead of no data!' },
                { id: 'valueProperty', name: 'Defines the property used for calculating the value of the lable', type: 'property', description: '' },                
                { id: 'aggregateOperation', name: 'Allows to define the aggregate operation used for calculating the value', type: 'value', values: ['sum', 'count', 'avg'], description: '' },
                { id: 'currencyValue', name: 'Select a currency of the value', type: 'value', values: ['nothing', 'detect', '€', '$', '%', 'Natural Number'], description: '' }                
            ]
        },        
        {             
            title: 'Data Grid', 
            description: 'The data grid is a very flexible widget that can be configured to display data in a variety of ways.' ,
            hasDatasource: true,
            type: 'GridWidget',
            attributes: [
                { id: 'periodsEnabled', name: 'Enable support for periods', type: 'bool', description: 'If true, the widget will support switching between different time periods' },
                { id: 'periodsAll', name: 'Show manually triggered periods', type: 'bool', description: 'If true, the widget will support switching between different time periods include manually triggered' },
                { id: 'multiplePeriodsSupported', name: 'Support multiple periods', type: 'bool', description: 'If true, the widget will support multiple periods at the same time', dependentOn: 'periodsEnabled' },                
                { id: 'multiplePeriodsMatchingProperty', name: 'Defines the property used for matching the records when injecting multiple periods', type: 'property', description: '', dependentOn: 'multiplePeriodsSupported' },
                { id: 'currencyValue', name: 'Select a currency of the value', type: 'value', values: ['nothing', 'detect', '€', '$', '%', 'Natural Number'], description: '' },                
                { id: 'detailsTemplate', name: 'Select how details will be visualized', type: 'value', values: ['Consumption Meters', 'Event List', 'No Details'], description: '' },        
                { id: 'detailsReportDefinition', name: 'Selected the report definition used for the details panel', type: 'reportdefinition', description: '', dependentOn: 'detailsTemplate', dependentOnValue: 'Event List' },           
                { id: 'detailsMatchingProperty', name: 'Defines the property used for matching the records when rendering the details', type: 'property', description: '', dependentOn: 'detailsTemplate', dependentOnValue: 'Event List' },
                { id: 'propertiesFormat', name: 'Format properties', type: 'map<property, format>', description: 'If used only the mapped properties are shown in the report, if empty all properties are shown in the report' },
            ]
        },
        {
            title: 'Stacked Bar Chart', 
            description: 'Allows to show data in a stacked bar chart.' ,
            hasDatasource: true,
            type: 'ChartWidgetStackBar',
            attributes: [                
                { id: 'groupProperty', name: 'Defines the property used for grouping the data', type: 'property', description: '' },
                { id: 'valueProperty', name: 'Defines the property used for calculating the value of every group', type: 'property', description: '' },
                { id: 'aggregateOperation', name: 'Allows to define the aggregate operation used for calculating the value', type: 'value', values: ['sum', 'count', 'avg'], description: '' },
                { id: 'currencyValue', name: 'Select a currency of the value', type: 'value', values: ['nothing', 'detect', '€', '$', '%', 'Natural Number'], description: '' },                
            ]
        },    
        {
            title: 'Column Chart', 
            description: 'Allows to map properties to dedicated columns and visualize the data in a column chart.' ,
            hasDatasource: true,
            type: 'ChartWidgetColumn',
            attributes: [                                
                { id: 'valueProperties', name: 'Defines the properties used for the columns. At least one property is mandatory', type: 'list<property>', description: '' },
                { id: 'aggregateOperation', name: 'Allows to define the aggregate operation used for calculating the value', type: 'value', values: ['sum', 'count', 'avg'], description: '' },
            ]
        },        
        {             
            title: 'Pie Chart', 
            description: 'Allows to show data in a pie chart.' ,
            hasDatasource: true,
            type: 'ChartWidgetPie',
            attributes: [
                { id: 'periodsEnabled', name: 'Enable support for periods', type: 'bool', description: 'If true, the widget will support switching between different time periods' },
                { id: 'periodsAll', name: 'Show manually triggered periods', type: 'bool', description: 'If true, the widget will support switching between different time periods include manually triggered' },
                { id: 'groupProperty', name: 'Defines the property used for grouping', type: 'property', description: '' },
                { id: 'valueProperty', name: 'Defines the property used for calculating the value of every group', type: 'property', description: '' },
                { id: 'aggregateOperation', name: 'Allows to define the aggregate operation used for calculating the value', type: 'value', values: ['sum', 'count', 'avg'], description: '' },
                { id: 'currencyValue', name: 'Select a currency of the value', type: 'value', values: ['nothing', 'detect', '€', '$', '%', 'Natural Number'], description: '' },                
            ]
        }
    ]

    constructor(
        private _tenantId: string, 
        private _token: string)
    {

    }

    getWidgetTemplates(): Promise<ISdkDashboardWidgetTemplate[]> {        
        return Promise.resolve<ISdkDashboardWidgetTemplate[]>(this._templates)
    }
   
}