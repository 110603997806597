import { IDropdownOption, PanelType, mergeStyleSets } from "@fluentui/react"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { useForm } from "react-hook-form"
import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter"
import { getMonthDefinition, getRelativeMonthDefinition } from "../lib/components/Helpers/DateHelpers"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { ControlledDropdown } from "../lib/forms/ControlledDropdown"
import { ISdkDataIntegration } from "../lib/sdk/models/ISdkDataIntegration"
import { SdkCustomerDataIntegrationClient } from "../lib/sdk/SdkCustomerDataIntegrationClient"
import { ICustomerManagementItem } from "./ServiceProviderCustomerManagementUIService"
import { SdkCustomerDatasourceClient } from "../lib/sdk/SdkCustomerDatasourceClient"
import { ControlledCheckbox } from "../lib/forms/ControlledCheckbox"

export interface IPeriodSelectorPanel extends IAppWindowBaseProps { 
    tenantId: string
    parentId: string
    parentType: "integration" | "customer"
    selectedItems: ISdkDataIntegration[] |ICustomerManagementItem[]
}
export const PeriodSelectorPanel = (props: IPeriodSelectorPanel) => {

    const auth = useAuthInContext(props.tenantId as string)

    type SelectedPeriod = {
        period: string,
        forcePrevious: boolean,
        cleanUpData: boolean
    }

    const { handleSubmit, control, reset, formState } = useForm<SelectedPeriod>({
        defaultValues: {
            period: getMonthDefinition(new Date())            
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });
    
    const onDismissDialog = () => {            

        reset()

        if (props.dismissDialog)
            props.dismissDialog()
    }

    const onSubmit = (): Promise<void> => {    
        
        const submitHandler = handleSubmit((data: SelectedPeriod) => {                                

            if (props.parentType === "integration") {
                const sdkClient = new SdkCustomerDataIntegrationClient(props.tenantId as string, auth.currentToken as string);
                const promisses = props.selectedItems.map((item) => sdkClient.syncDataIntegration(item.id, data.period, data.cleanUpData, data.forcePrevious))                    
                return Promise.all(promisses)            
            } else if (props.parentType === 'customer') {
                const sdkClient = new SdkCustomerDatasourceClient(props.tenantId as string, props.parentId, auth.currentToken as string);
                const promisses = props.selectedItems.map((item) => (item as ICustomerManagementItem).editable ? sdkClient.syncDatasource(item.id, data.period, data.cleanUpData) : null)                    
                return Promise.all(promisses)            
            } else {
                return Promise.resolve()
            }
        })

        return submitHandler()        
    }

    const availablePeriods: IDropdownOption[] = []
    for(let i = -60; i <= 0; i++) {

        const monthString = getRelativeMonthDefinition(i);

        availablePeriods.push({
            key: monthString,
            text: monthString
        })
    }

    availablePeriods.reverse()

    const classNames = mergeStyleSets({
        checkbox: {
            marginTop: '10px'
        }
    })

    const showForecePrevious = props.parentType === 'integration' && props.selectedItems && props.selectedItems.length > 0 && props.selectedItems[0].type.indexOf('MSFT.PartnerCenter') !== -1;
    
    return (
        <PanelWithDefaultFooter
            isOpen={props.isVisible}
            headerText={'Select a period'}
            onDismiss={onDismissDialog}
            onSubmit={onSubmit}
            type={PanelType.medium} 
            progessMessage={'Trigger operation....'} 
            submitLabel={'Ok'} 
            dissmissLabel={'Cancel'} 
            isValid={formState.isValid}>

            <p>Please select a period the operation should be executed for</p>    

            <ControlledDropdown key={'validUntil'} control={control} name={'period'} rules={{ required: 'a valid value is required'}} options={availablePeriods} />            

            { showForecePrevious && (
                <ControlledCheckbox key={'forcePrevious'} control={control} name={'forcePrevious'} label={'Prefer unbilled-previous usage workspace even when invoice is available'} className={classNames.checkbox} />            
            )}

            <ControlledCheckbox key={'cleanUpData'} control={control} name={'cleanUpData'} label={'Removing existing data of the selected period during the data-synchronization'} className={classNames.checkbox} />            
                            
        </PanelWithDefaultFooter>            
    )
}