import { MessageBar, MessageBarType } from "@fluentui/react"
import { useBoolean } from "@fluentui/react-hooks"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { ICommandPermission } from "../lib/commands/ICommandPermission"
import { IColumnsBuilder } from "../lib/components/DetailsListHelper/ColumnsBuilder"
import { ICommandBarService } from "../lib/components/DetailsListHelper/ComandBarService"
import { NavigationalDetailsListWithStandardOperations } from "../lib/components/DetailsListHelper/NavigationalDetailsListWithStandardOperations"
import { PageHeader } from "../lib/ds/components/PageHeader"
import { ISdkUserProfile } from "../lib/sdk/models/ISdkUserProfile"
import { SdkTenantUserProfileClient } from "../lib/sdk/SdkTenantUserProfileClient"
import { TenantUserManagementAddApplication } from "./TenantUserManagementAddApplication"
import { TenantUserManagementAddUser } from "./TenantUserManagementAddUser"
import { TenantUserManagementRoleAssignments } from "./TenantUserManagementRoleAssignments"

import dayjs from "dayjs"
import relativeTime from 'dayjs/plugin/relativeTime'
import { useProfileContext } from "../lib/authentication/AuthenticationProfileContext"
import { useSubscriptionContext } from "../lib/authentication/AuthenticationSubscriptionContext"
dayjs.extend(relativeTime)

export interface ITenantUserManagementProps {
}

export const TenantUserManagement = (props: ITenantUserManagementProps) => {
    
    // get the current tenant state
    const { tenantId } = useParams()         
    const { profile} = useProfileContext()

    // get the authenticaiton 
    const auth = useAuthInContext(tenantId as string)

    // get the subscription context
    const sub = useSubscriptionContext()    
        
    // establish the item state
    const [error/*, setError*/] = useState('')

    
    const [clickedItem, setClickedItem] = useState<ISdkUserProfile>();

    const [isRefreshRequest, { toggle: toggleRefreshRequest}] = useBoolean(false)    
    const [isAddUserOpen, { setTrue: showAddUser, setFalse: hideAddUser}] = useBoolean(false)
    const [isAddApplicationOpen, { setTrue: showAddApplication, setFalse: hideAddApplication}] = useBoolean(false)
    const [isUserDetailsOpen, { setTrue: showUserDetails, setFalse: hideUserDetails}] = useBoolean(false)
    const [permissionLevels, setPermissionLevels] = useState<{ [key: string]: string}>({})

    const columnClickOperation = (item: ISdkUserProfile) => {
        setClickedItem(item)
        showUserDetails()
    }

    const getDissmissDialogOperation = (hideOperation: () => void): () => void  => {        
        return () => {
            hideOperation()             
            toggleRefreshRequest()
        }
    }
    
    const buildColumns = (builder: IColumnsBuilder<ISdkUserProfile>): void  => 
    {        
        builder.IconColumn({name: "Type", headerIcon: 'faUsers', iconName: (item) => {
            if (item.contactAddresses && item.contactAddresses.length > 0 && item.contactAddresses[0].indexOf('@applications') > -1) 
                return 'faCircleNodes'
            else if (item.contactAddresses && item.contactAddresses.length > 0 && item.contactAddresses[0].indexOf('@service-principals') > -1) 
                return 'faImagePortrait'
            else
                return 'faUser'
        }, onClick: columnClickOperation})
        builder.TextColumn({name: "Display Name", value: (item) => { 
            const youMessage = profile && profile.profileId === item.userId ? ' (you)' : ''
            return item.firstName + ' ' + item.lastName + youMessage
        }, maxWidth: 250, onClick: columnClickOperation})            
        builder.TextColumn({name: "Permission Level", value: (item) => {
            if (!(item as any).permissionLevel)
                return 'Evaluating...'
            else
                return (item as any).permissionLevel
        }, maxWidth: 150, onClick: columnClickOperation})
        builder.TextColumn({name: "Id", value: "userId", maxWidth: 250, onClick: columnClickOperation})
        builder.TextColumn({name: "Last Login", value: (item => {
            if (!item.lastLogin || item.lastLogin.startsWith('0001-')) {
                return 'never'
            } else {
                return dayjs(item.lastLogin).fromNow()
            }       
        }), maxWidth: 250, onClick: columnClickOperation})
        builder.TextColumn({name: "Contact Addresses", value: (item) => { return item.contactAddresses ? item.contactAddresses.join(', ') : ''}, maxWidth: 150, onClick: columnClickOperation})                              
    }
        
    const reloadData = (): Promise<ISdkUserProfile[]> => {
        const client = new SdkTenantUserProfileClient(tenantId as string, auth.currentToken as string)
        return client.listUserProfiles().then((profiles) => {

            // search the profiles not reflectedin permission levels
            const notReflected = profiles.filter((p) => !permissionLevels[p.userId as string])

            // reevaluate the missing permission levels in an async manner
            if (notReflected.length > 0) {
                reEvaluatePermissionLevels(notReflected, client)
            }

            // inject the permission levels in the profiles 
            profiles.forEach((p) => {
                (p as any).permissionLevel = permissionLevels[p.userId as string]
            })

            // return the profiles
            return profiles
        })      
    }

    const reEvaluatePermissionLevels = async (profiles: ISdkUserProfile[], client: SdkTenantUserProfileClient) => {
        
        const calculatedPermissionLevels: { [key: string]: string} = {}

        const privilegedRoles = ['Tenant Owner', 'Resource Manager', 'Cloud Billing Customer Manager', 'Cloud Billing Credential Manager']

        // visit all profiles and execute async operations
        for(let i = 0; i < profiles.length; i++) {
            
            // get the profile
            const p = profiles[i]

            // ask for the roles 
            const roles = await client.loadRoleAssignments(p.userId)

            // verify if the roles are in privilegedRoles list            
            const isPrivileged = roles.some((r) => privilegedRoles.includes(r.roleIdentifier))            

            // assigb the correct permission level
            const permissionLevel = isPrivileged ? 'Privileged Permissions' : 'Standard Permissions'
            calculatedPermissionLevels[p.userId as string] = permissionLevel
        }

        // set the permision levels
        setPermissionLevels(prev => Object.assign({}, prev, calculatedPermissionLevels))   

        // refresh the list
        toggleRefreshRequest()            
    }    
    
    const addUser = (): Promise<void> => {
        showAddUser()
        return Promise.resolve()
    }

    const addApplication = (): Promise<void> => {
        showAddApplication()
        return Promise.resolve()
    }

    const onDeleteItems = (items: ISdkUserProfile[]): Promise<void> => {        
        const client = new SdkTenantUserProfileClient(tenantId as string, auth.currentToken as string);         

        const notAllowedItems = items.filter((item) => item.firstName.toLocaleLowerCase().indexOf('scim') !== -1)
        if (notAllowedItems.length > 0) {
            return Promise.reject('Cannot delete service principals for SCIM')
        }

        const itemsToDeletePromisses = items.map((item) => client.deleteUserFromTenant(item.userId))
        return Promise.all(itemsToDeletePromisses)
            .then(() => Promise.resolve())
            .catch(() => Promise.reject())
    }    
    
    const onAddCommands = (commandBarService: ICommandBarService, commandPermissions: ICommandPermission[]) => {
        if (sub && sub.subscriptions && sub.subscriptions.find((s) => !s.id.endsWith('.managedcustomer'))) {
            commandBarService.Command('addApplication', 'Add Application', 'faPlus', addApplication, undefined, undefined, undefined, 'new')
        }
    }

    return (
        <div className="mv-content-container">

            <PageHeader title={'User Management'}>            
                <span>Manage all users having access to your tenant, there are 3 different kind of users. A Tenant Member is a user</span>
            </PageHeader>

            { error && error.length > 0 ? (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                    {'Error: ' + error}
                </MessageBar>
            ) : (<></>)}
                
        
            <NavigationalDetailsListWithStandardOperations<ISdkUserProfile> 
                uniqueIdentifier='usersroot2' 
                onBuildColumns={buildColumns} 
                onLoadData={reloadData} 
                onDeleteItems={onDeleteItems} 
                onNewItem={addUser} 
                newItemLabel='Add User'
                onAddCommands={onAddCommands}
                refresh={isRefreshRequest} />

            <TenantUserManagementAddUser isVisible={isAddUserOpen} dismissDialog={getDissmissDialogOperation(hideAddUser)} tenantId={tenantId as string} />            
            <TenantUserManagementAddApplication isVisible={isAddApplicationOpen} dismissDialog={getDissmissDialogOperation(hideAddApplication)} tenantId={tenantId as string} />
            <TenantUserManagementRoleAssignments isVisible={isUserDetailsOpen} dismissDialog={getDissmissDialogOperation(hideUserDetails)} tenantId={tenantId as string} profile={clickedItem as ISdkUserProfile} />            
        </div>
    ) 
}

