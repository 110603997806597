import { ControlledCheckbox } from "../forms/ControlledCheckbox"
import { ControlledDropdown } from "../forms/ControlledDropdown"
import { ControlledTextField } from "../forms/ControlledTextField"
import { ISdkObjectFromTemplateFields, ISdkObjectTemplate, ISdkObjectTemplateField } from "../sdk/models/ISdkObjectTemplate"
import { ControlledFieldList } from "../forms/ControlledFieldList"
import { ControlledPropertyMapItemList } from "../forms/ControlledPropertyMapItemList"
import { ControlledMonthField } from "../forms/ControlledMonthField"
import { ControlledNumberField } from "../forms/ControlledNumberField"
import { ControlledSchedulerField } from "../forms/ControlledSchedulerField"
import { Control } from "react-hook-form"
import { IDropdownOption, Label, mergeStyleSets } from "@fluentui/react"
import { ISdkSchedulerDefinition } from "../sdk/models/ISdkSchedulerDefinition"
import { ControlledFileContentField } from "../forms/ControlledFileContentField"

const renderTemplateClassNames = mergeStyleSets({
    checkbox: {
        marginTop: '10px'
    }
})

export const renderFieldItem = (f: ISdkObjectTemplateField, control: Control<any, any>, isProcessing: boolean, availableObjectLists: { [key: string]: IDropdownOption[] }) => {                
    if (f.type === 'boolean') {
        return (<ControlledCheckbox key={f.id} label={f.name} control={control} name={f.id} disabled={isProcessing || f.disabled} className={renderTemplateClassNames.checkbox} />)            
    } else if (f.type === 'password') {
        return (<ControlledTextField key={f.id} label={f.name} control={control} name={f.id} rules={{ required: f.isOptional ? undefined : 'a valid value is required'}} disabled={isProcessing || f.disabled} type={'password'} />)
    } else if (f.type === 'string' && f.values) {          
        const propertyOptions = Object.keys(f.values).map(v => { return { key: v, text: f.values ? f.values[v] : v } })   
        return (<ControlledDropdown key={f.id} label={f.name} control={control} name={f.id}  rules={{ required: f.isOptional ? undefined : 'a valid value is required'}} options={propertyOptions} disabled={isProcessing || f.disabled}/>)
    } else if (f.type === 'object') {
        return (<ControlledDropdown key={f.id} label={f.name} control={control} name={f.id}  rules={{ required: f.isOptional ? undefined : 'a valid value is required'}} options={availableObjectLists[f.id] || []} disabled={isProcessing || f.disabled}/>)
    } else if (f.type === 'list<object>') {        
        return (
            <div style={{marginTop: '10px'}}>
                <Label>{f.name}</Label> 
                <ControlledFieldList key={f.id} label={f.name} control={control} name={f.id}  rules={{ required: f.isOptional ? undefined : 'a valid value is required'}} availableFields={availableObjectLists[f.id] || []} disabled={isProcessing || f.disabled}/>
            </div>
        )            
    } else if (f.type === 'map<string, string>') {  
        return (
            <div style={{marginTop: '10px'}}>
                <Label>{f.name}</Label> 
                <ControlledPropertyMapItemList key={f.id} label={f.name} control={control} name={f.id}  disabled={isProcessing || f.disabled} placeholderLeft="Name" placeholderRight="Value"/>
            </div>)   
    } else if (f.type === 'month') {  
        return (<ControlledMonthField key={f.id} label={f.name} control={control} name={f.id} rules={{ required: f.isOptional ? undefined : 'a valid value is required'}} disabled={isProcessing || f.disabled} />)
    } else if (f.type === 'number') {  
        return (<ControlledNumberField key={f.id} label={f.name} control={control} name={f.id} rules={{ required: f.isOptional ? undefined : 'a valid value is required'}} disabled={isProcessing || f.disabled} />)
    } else if (f.type === 'scheduler') {  
        return (
            <div style={{marginTop: '10px'}}>
                <Label>{f.name}</Label> 
                <ControlledSchedulerField key={f.id} control={control} name={f.id} rules={{ required: f.isOptional ? undefined : 'a valid value is required'}} />
            </div>)            
    } else if (f.type === 'text') {  
        return (<ControlledTextField key={f.id} label={f.name} control={control} name={f.id} rules={{ required: f.isOptional ? undefined : 'a valid value is required' }} disabled={isProcessing || f.disabled} multiline={true} />)
    } else if (f.type === 'filecontent') {  
        const acceptedFileTypes: string[] = []
        
        if (f.values) {
            Object.keys(f.values || {}).forEach((key) => {
                acceptedFileTypes.push(key)
            })
        }
        
        return (<ControlledFileContentField key={f.id} label={f.name} control={control} name={f.id}  acceptedContentTypes={acceptedFileTypes} rules={{ required: f.isOptional ? undefined : 'a valid value is required' }} disabled={isProcessing || f.disabled} />)        
    } else {                    
        return (<ControlledTextField key={f.id} label={f.name} control={control} name={f.id} rules={{ required: f.isOptional ? undefined : 'a valid value is required' }} disabled={isProcessing || f.disabled} />)
    }
}

export const renderTemplate = (control: Control<any, any>, isProcessing: boolean, objectDescriptionHidden: boolean, availableObjectLists: { [key: string]: IDropdownOption[] }, selectedTemplate: ISdkObjectTemplate,  optionalFields?: ISdkObjectTemplateField[]) => {
    return (
        <>
            <ControlledTextField key={'name'} label={'Name'} control={control} name={'name'} rules={{ required: 'a valid valud is required'}} disabled={isProcessing} />                                                                     
            { !objectDescriptionHidden && <ControlledTextField key={'comment'} label={'Description'} control={control} name={'comment'} disabled={isProcessing} /> }

            { optionalFields && optionalFields.map(f => renderFieldItem(f, control, isProcessing, availableObjectLists))}                                
            { selectedTemplate && selectedTemplate.fieldSectionText && <p>{selectedTemplate.fieldSectionText}</p>}
            { selectedTemplate && selectedTemplate.fields.map(f => renderFieldItem(f, control, isProcessing, availableObjectLists))}                                
        </>                     
    )
}

export const extractFieldValues = (data: any, selectedTemplate: ISdkObjectTemplate) => {
    
    // build the field values 
    const fieldValues: { [key: string]: any } = {}                

    for(let fieldDef of selectedTemplate?.fields) {
        fieldValues[fieldDef.id] = data[fieldDef.id]

        if (fieldDef.type === 'list<object>' && fieldValues[fieldDef.id]) {
            // convert the object list to a comma seperated list of ids
            const concatedFieldValues = (fieldValues[fieldDef.id] as any[]).map((item) => item.field).join(',')
            fieldValues[fieldDef.id] = concatedFieldValues
        } else if (fieldDef.type === 'map<string, string>' && fieldValues[fieldDef.id]) {
            const concatedFieldValues = (fieldValues[fieldDef.id] as any[]).map((item) => item.left + ',' + item.right).join(',')
            fieldValues[fieldDef.id] = concatedFieldValues
        } else if (fieldDef.type === 'boolean') {
            fieldValues[fieldDef.id] = (data as any)[fieldDef.id] ? "true" : "false"
        } else if (fieldDef.type === 'scheduler') {
            fieldValues[fieldDef.id] = JSON.stringify((data as any)[fieldDef.id])
        }
    }

    return fieldValues
}

export const extractOptionalFieldValues = (data: any, optionalFields?: ISdkObjectTemplateField[]) => {
    
    const optionalFieldValues: { [key: string]: any } = {}
    if (!optionalFields) { return optionalFieldValues }
    
    for(let fieldDef of optionalFields) {
        optionalFieldValues[fieldDef.id] = (data as any)[fieldDef.id]
    }
    
    return optionalFieldValues;
}

export const buildViewModelFromFieldValues = (fieldValues: ISdkObjectFromTemplateFields, template: ISdkObjectTemplate, localAvailableObjectLists: { [key: string]: IDropdownOption[] }, baseViewModel: any): any => {

    let viewItem: any = {...baseViewModel}

    Object.keys(fieldValues).forEach((key) => {
        viewItem[key] = fieldValues[key] 
        
        const fieldDef = template?.fields.find(t => t.id === key)
        if (fieldDef && fieldDef.type === 'list<object>') {
            // convert the comma seperated list of ids to a list of objects
            viewItem[key] = (fieldValues[key] as string).split(',')
                .filter((id) => id && id.length > 0 )
                .map((id, index) => {
                    const object = localAvailableObjectLists[key].find((o) => o.key === id)                    
                    return { field: object?.key, key: index }
            })
        } else if (fieldDef && fieldDef.type === 'map<string, string>') {

            var splittedValues = viewItem[key].split(',')

            var valueList = []
            for(let i = 0; i < splittedValues.length; i = i + 2) {
                valueList.push({ left: splittedValues[i], right: splittedValues[i + 1] })
            }

            viewItem[key] = valueList
        } else if (fieldDef && fieldDef.type === 'boolean') {           
            viewItem[key] = fieldValues[key] === 'true' ? true : false
        } else if (fieldDef && fieldDef.type === 'scheduler') {                                    

            const parsedModel = JSON.parse(viewItem[key])
            
            viewItem[key] =  {
                referenceDate: parsedModel.referenceDate ? new Date(parsedModel.referenceDate) : undefined,
                interval: parsedModel.interval,
                unitOfTime: parsedModel.unitOfTime,  
                daysOfWeek: parsedModel.daysOfWeek    
            } as ISdkSchedulerDefinition            
        }
    })

    return viewItem
}

export const buildViewModelFromOptionalFieldValues = (fieldValues: ISdkObjectFromTemplateFields, baseViewModel: any): any => {
    
    let viewItem: any = {...baseViewModel}

    Object.keys(fieldValues).forEach((key) => {
        viewItem[key] = fieldValues[key]
    })

    return viewItem
}